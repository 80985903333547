.change-profile-photo-content {
    width: 300px;
    height: 310px;
    display: flex;
    flex-direction: column;
}

.profile-photo-loading-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.select-profile-photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.no-profile-photo-selected-circle {
    width: 175px;
    height: 175px;
    border: 1px solid #a2a1b7;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.update-photo-avatar {
    width: 175px;
    height: 175px;
    font-size: 42px;
    margin-bottom: 8px;
    margin-left: 10px;
}

#select-new-profile-photo-button {
    font-weight: 800;
}

#cancel-change-profile-photo-button {
    background-color: gray;
}
