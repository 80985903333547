/* Temporary dashboard message */
.data-dashboard-message {
    font-style: italic;
    color: #484848;
}

.data-dashboard-grid {
    flex-grow: 1;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    overflow: auto;
}

.dashboard-grid-item {
    min-width: 500px;
    max-width: 600px;
    height: auto;
}

.dashboard-grid-item-small {
    min-width: 400px;
}
.dashboard-grid-item-large {
    min-width: 600px;
}

.dashboard-grid-item-content {
    padding: 30px;
}

.dashboard-grid-item-header {
    text-align: left;
    color: #240145;
    margin-bottom: 10px;
    margin-top: 0px;
}

.dashboard-grid-item-border {
    height: 100%;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
}

.dashboard-sub-header {
    text-align: left;
    font-style: italic;
    color: #484848;
    margin-left: 4px;
}

.dashboard-more-info-free-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.personal-school-year-dropdown {
    margin-bottom: 10px;
    margin-right: 40px;
    min-width: 120px;
    margin-left: 30px;
}

.dashboard-page-header {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
}
