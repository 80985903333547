.users-data-grid-box {
    height: 400;
    width: 100%;
}

.manage-users-loading {
    justify-content: center;
}

.users-no-rows-text {
    margin: 20px;
    color: black;
}