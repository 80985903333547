#cancel-forgot-password-button {
    background-color: gray;
}

.forgot-password-progress {
    display: flex;
    justify-content: center;
}

.forgot-password-email {
    margin-top: 10px;
}

.forgot-password-error-message {
    color: red;
    font-style: italic;
}

.forgot-password-dialog-content {
    width: 500px;
    height: 100px;
}

.forgot-password-dialog-content-mobile {
    width: 220px;
    height: 170px;
}
