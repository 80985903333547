.login-grid {
    height: 100vh;
    width: 100vw;
}

.login-display {
    background-color: #240145;
}

.login-company-name {
    color: white;
}

.login-logo {
    height: 100px;
}

.clever-logo {
    height: 20px;
}

.clever-box .clever-login-button {
    width: 200px;
    text-transform: none;
    border-color: lightgray;
}

.third-party-login-button {
    margin-top: 10px;
}

.login-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-box .login-icon {
    background-color: #240145;
}

.login-form .login-button {
    background-color: #240145;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 200px;
}

.login-form .login-forgot-password-item {
    margin-top: 10px;
}

.login-form .login-form-item {
    margin-top: 20px;
}

.invalid-password-text {
    color: red;
    font-style: italic;
}

.login-forgot-password .forgot-password-button {
    color: #240145;
    text-transform: none;
}

.login-divider {
    padding: 10px;
}

.login-box {
    margin-top: 20px;
}

.login-box-title {
    margin-bottom: 10px;
}

.login-box .install-mobile-button{
    margin-top: 10px;
}

.footer {
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.privacy-policy-button {
    margin-right: 10px;
    color: gray;
}

.copy-right-footer {
   margin-left: 10px;
}

.login-help-text {
    font-size: 14px;
    margin-top: 30px;
    color: gray;
}