
.current-users-header {
    display: flex;
}

.clever-manage-users-warning {
    display: flex;
}

.clever-manage-users-text {
    margin-left: 10px;
    font-style: italic;
}

.create-user-button-div {
    display: flex;
    justify-content: right;
    margin-left: auto;
}

#create-user-button {
    margin-bottom: 10px;
}

.current-users-instructions {
    padding-bottom: 15px;
    text-align: left;
}
.create-users-bold-text {
    color: #240145;
    font-weight: bold;
}

.edit-user-column-name {
    color: #240145;
    /* font-weight: bold !important; */
}

.MuiDataGrid-columnHeaderTitle { font-weight: bold !important; }