.feed-list-item {
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}

.feed-list-item-header {
    display: flex;
    width: 100%
}

.feed-list-item-menu-options-box {
    margin-left: auto;
    margin-right:  0px;
}
.feed-header-box {
    display: flex;
    align-items: center;
}
.feed-header-photo {
    margin: 5px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
}

.feed-list-item-avatar {
    margin: 5px;
    height: 48px;
    width: 48px;
}

.feed-header-bold {
    font-weight: bold;
    color: #240145;
}

.feed-header-text {
    color: #a2a1b7;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 5px;
}

.feed-header-school-name {
    color: #a2a1b7;
    font-style: bold;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 5px;
}

.feed-item-footer{
    display: flex;
    margin-top: 4px;
}

.feed-item-time {
    color: #a2a1b7;
    margin-top: 0px;
    margin-left: auto;
    margin-bottom: 5px;
    text-align: right;
}

.feed-list-item-text {
    flex-basis: 100%;
    color: #240145;
    margin-left: 10px;
    margin-top: 0px;
}

.feed-list-item-text p {
    margin-top: 5px;
}

.feed-text-from {
    color: #fcbe57;
    font-weight: bold;
}

.feed-text-paragraph {
    margin-bottom: 0px;
}

.view-certificate-button {
    margin-left: 10px;
    margin-top: 10px;
}