
.reward-permmissions-progress {
    display: flex;
    justify-content: center;
}

.reward-admin-settings-grid-item-header {
    text-align: left;
    color: #240145;
    margin-top: 8px;
    margin-bottom: 0px;
    margin-left: 10px;
}

.reward-admin-settings-grid-item-header-no-button {
    text-align: left;
    color: #240145;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
}

.reward-admin-settings-grid-item-border {
    height: 100%;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    padding-top: 10px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;
}

.reward-admin-settings-grid-item-border-no-button {
    height: 100%;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    padding-top: 15px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 15px;
}

.current-rewards-instructions {
    padding-bottom: 30px;
    text-align: left;
}

.current-rewards-header {
    display: flex;
    width: 100%;
}

.create-reward-button-div {
    display: flex;
    justify-content: right;
    margin-left: auto;
}

.rewards-accordian {
    box-shadow: none;
}

.rewards-accordian-summary {
    padding: 0;
    flex-direction: row-reverse;
}

#create-reward-button {
    margin-bottom: 10px;
}
