.edit-reward-popup-content {
    margin-left: 10px;
    padding-top: 0px;
    width: 580px;
}

.edit-reward-popup-content-mobile {
    margin-left: 10px;
    width: 320px;
}

.edit-reward-bold-text {
    font-weight: bold;
    color: #240145;
}

.reactivate-reward-text {
    padding-bottom: 10px;
}

.edit-reward-field {
    margin: 10px;
}

.edit-reward-school {
    margin-left: 10px;
}

.edit-reward-loading {
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}

.create-reward-instructions{
    margin-bottom: 10px;
}

.edit-reward-cost-field {
    width: 105px;
}

.edit-reward-top-box {
    display: flex;
}

.edit-reward-preview-header {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 15px;
    color: #240145;
}

.edit-reward-text-field {
    width: 230px;
}

.edit-reward-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.edit-reward-preview {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.edit-reward-wide-field {
    width: 280px;
}

#edit-reward-instructions-field {
    width: 470px;
}

#cancel-edit-reward-button {
    background-color: gray;
}

#change-reward-photo-button {
    align-self: center;
}