.spotlight-grid {
    flex-grow: 1;
    margin: 20px;
}

.spotlight-grid .print-view-header {
    margin: 20px;
}

.spotlight-grid .print-view-header-title {
    color: #240145;
    font-weight: 600;
    font-size: 26px;
    text-align: left;
}

.spotlight-grid .spotlight-grid-item {
    width: auto;
    height: auto;
    margin-left: 30px;
}

.spotlight-grid .spotlight-grid-item-mobile {
    width: auto;
    height: auto;
    margin-left: 10px;
}

.spotlight-grid .spotlight-percentage-item {
    height: 180px;
    width: 180px;
    min-height: 180px;
    min-width: 180px;
    margin-right: 20px;
}

.spotlight-grid .spotlight-grid-item-border {
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    margin-bottom: 20px;
}

.spotlight-grid .spotlight-grid-item-box {
    width: 390px;
}

.spotlight-grid .spotlight-grid-item-box-mobile {
    width: 250px;
}

.spotlight-grid .spotlight-grid-item-box-print {
    width: 300px;
}

.spotlight-percentages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.spotlight-grid .spotlight-item-header {
    font-size: large;
    font-weight: 600;
    color: #240145;
    padding: 15px;
    text-align: center;
}

.spotlight-grid .spotlight-item-smaller-header {
    font-size: medium;
    font-weight: 600;
    color: #240145;
    padding: 10px;
    text-align: center;
}

.spotlight-percentages .spotlight-percentage-text {
    font-size: 46px;
    font-weight: 900;
    margin-top: 10px;
    color: #fcbe57;
    text-align: center;
}

.spotlight-grid .spotlight-token-type-header {
    font-size: 24px;
    font-weight: 800;
    color: #240145;
    margin-left: 10px;
}

.spotlight-grid .spotlight-token-type-text {
    text-align: left;
    margin-left: 20px;
}

.spotlight-grid .spotlight-token-type-text-mobile {
    text-align: center;
}

.spotlight-recognition-examples-text {
    margin: 20px;
}

.spotlight-token-icon {
    color: #fcbe57;
}

.spotlight-top-sender-text {
    margin-bottom: 10px;
    margin-left: 20px;
    width: 250px;
    overflow: scroll;
    text-align: left;
    display: flex;
}

.spotlight-top-sender-text-mobile {
    margin-bottom: 10px;
    margin-left: 10px;
    width: 150px;
    overflow: wrap;
    text-align: left;
    display: flex-wrap;
}

.spotlight-top-sender-name-wrap {
    display: flex;
    align-items: center;
}

.sent-text {
    margin-left: 2px;
    margin-top: 1px;
}

.top-sender-name {
    font-weight: bold;
    font-size: large;
    margin-right: 4px;
    color: #240145;
    align-content: center;
}

.spotlight-count {
    font-weight: bold;
    font-size: xx-large;
    color: #fcbe57;
    margin-left: 4px;
    margin-right: 4px;
}

.top-sender-avatar {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.top-sender-avatar-mobile {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    margin-left: 10px;
}

.spotlight-top-sender-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.spotlight-no-data-text {
    margin-bottom: 10px;
    margin-left: 4px;
    margin-right: 4px;
}

.spotlight-feed-list {
    margin-bottom: 10px;
}

.spotlight-print-view-warnig-text {
    font-style: italic;
}

#spotlight-recognition-examples {
    min-height: 300px;
    min-width: 200px;
}

#spotlight-token-type {
    height: 120px;
    width: 390px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
}

#spotlight-token-type-mobile {
    height: 160px;
    width: 250px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

#spotlight-token-type-logo {
    height: 40px;
    margin-left: 10px;
}
