.sidebar {
    background-color: #240145;
    color: white;
    height: auto;
}

.sidebar-open {
    width: 330px;
    min-width: 330px;
}

.sidebar-closed {
    width: 62px;
}

.sidebar-top {
    display: flex;
    flex-direction: row;
}

.sidebar-top-closed {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.sidebar-logo {
    height: 40px;
    width: 40px;
    margin-top: 10px;
    margin-left: 10px;
}

.sidebar-toggle-button {
    color: white;
    display: contents;
}

.sidebar-toggle-open {
    display: flex;
    margin-left: auto;
    margin-right: 4px;
}

.sidebar-toggle-closed {
    display: flex;
    justify-content: center;
}

.sidebar-toggle-button .sidebar-toggle-icon {
    font-size: 40px;
}

#toggle-icon-left {
    margin-top: 8px;
}

#toggle-icon-right {
    margin-top: 10px;
}

.sidebar-icon {
    color: white;
    min-width: 20px;
}

.sidebar-school-name {
    font-weight: 500;
    font-size: 18px;
    margin-top: 16px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
}

.sidebar-school-name-select {
    font-weight: 500;
    font-size: 18px;
    margin-right: 10px;
    white-space: nowrap;
    color: white;
}

.school-select-form-field {
    color: white;
}

.sidebar-list-item {
    min-width: 30px;
}

.side-bar-list-item-text {
    margin-left: 15px;
}

.sidebar-user-summary {
    margin-top: 15px;
    margin-bottom: 15px;
}

.base-page {
    display: flex;
}

.main-box {
    background-color: white;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.coming-soon {
    font-style: italic;
}

.footer {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.privacy-policy-button {
    margin-right: 10px;
    color: gray;
}

.copyright {
   margin-left: 10px;
}

.sidebar-install-mobile {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.sidebar-install-mobile-button {
    background-color: #fcbe57;
    color: white;
    margin-top: 6px;
}
