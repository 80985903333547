#close-homescreen-popup-button {
    background-color: gray;
}

.add-to-homescreen-logo {
    height: 40px;
    width: 40px;
}

.add-to-homescreen-header {
    display: flex;
}

.add-to-homescreen-header .add-to-homescreen-header-text {
    margin-top: 10px;
    margin-left: 10px;
    font-size:20px;
    text-align: center;
}

.add-to-homescreen-content {
    text-align: center;
}

.add-to-homescreen-content .add-to-homescreen-instructions-text {
    margin-top: 10px;
}

.add-to-homescreen-content .add-to-homescreen-subtext {
    margin-top: 20px;
    font-size: small;
    font-style: italic;
}

.add-to-homescreen-help-icon {
    color: #fcbe57;
    margin-right: 10px;
    margin-top: 10px;;

}

.add-to-homescreeen-help-tooltip {
    max-width: 200px;
}