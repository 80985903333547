.staff-kreds-chart-box {
    min-height: 500px;
    min-width: 630px;
    width: auto;
}

.staff-kreds-chart-panel-box {
    min-height: 400px;
    min-width: 600px;
    width: auto;
    /* height: 600px; */
}

.staff-tokens-dropdown {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 10px;
}

.admin-data-loading-progess {
    min-height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-data-tab-panel {
    min-height: 450px;
}