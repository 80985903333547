.admin-settings-box {
    margin: 20px;
    min-width: 720px;
}

.admin-settings-no-permission-text-box {
    margin-left: 30px;
    margin-top: 20px;
    margin-right: 30px;
}

.admin-settings-tabs {
    border-color: #a2a1b7;
    border-bottom: 1px solid;
}


.admin-settings-grid-item-header {
    text-align: left;
    color: #240145;
    margin-bottom: 10px;
    margin-top: 0px;
}

.admin-settings-grid-item-border {
    height: 100%;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    padding: 30px;
}