.feed-yellow-bold-text {
    font-size: large;
    font-weight: bold;
    color: #fcbe57
}

.student-feed-permission-text {
    margin-bottom: 20px;
}

.feed-list-header {
    display: flex;
}

.feed-list-export-button {
    margin-right: 0px;
    margin-left: auto;
}

.hilight-count-text {
    width: 100%;
}

.export-button {
    align-self: center;
}