.deactivate-reward-popup-content {
    margin-left: 10px;
    width: 550px;
}

.deactivate-reward-popup-content-mobile {
    margin-left: 10px;
    width: 320px;
}

.deactivate-reward-loading {
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}

.deactivate-reward-bold-text {
    color: #240145;
    font-weight: bold;
}

.deactivate-reward-popup-sub-text {
    margin-top: 20px;
    font-style: italic;
}

#cancel-deactivate-reward-button {
    background-color: gray;
}
