.rewards-data-grid-box {
    height: 400;
    width: 100%;
}

.rewards-no-rows-text {
    margin: 20px;
    color: black;
}

.rewards-export{
    display: flex;
    margin-left: 4px;
    z-index: 1;
    margin-top: 10px;
    margin-bottom: -40px;
    width: 100px;
}