.overall-statistics-chart-box {
    height: auto;
    padding-right: 10px;
}

.overall-statistics-chart-tabs {
    margin-right: 30px;
    border-color: #a2a1b7;
    border-bottom: 1px solid;
}

.overall-statistics-chart-tab-box {
    height: 400px;
}
