.change-password-popup-content {
    margin-left: 10px;
}
.change-passwords-inputs {
    display: block;
    margin-top: 10px;
}

.change-password-item {
    margin-top: 10px;
}

.change-password-error-message {
    color: red;
    font-style: italic;
    margin-top: 4px;
    margin-left: 2px;
}

#cancel-change-password-button {
    background-color: gray;
}

.change-password-requirement {
    margin-left: 12px;
}
