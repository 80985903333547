.staff-points-data-grid-box {
    height: 400;
    width: 100%;
}

.staff-points-no-rows-text {
    margin: 20px;
    color: black;
}

.staff-points-footer {
    display: flex;
}

.staff-points-export{
    display: flex;
    margin-left: 4px;
    width: 100px;
}

.staff-points-footer-pagination {
    margin-left: auto;
}