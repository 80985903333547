.cajon-promise-border {
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 5px;
    &:hover {
        border: 3px #a2a1b7 solid;
        border-radius: 30px;
    }
}

.cajon-promise-selected {
    border: 3px #a2a1b7 solid;
    border-radius: 30px;
}

.cajon-core-value-img {
    width: 350px;
}

.cajon-core-value-img-mobile {
    width: 225px;
}

.cajon-core-value-default {
    display: flex;
    flex-direction: column;
    width: 350px;
    align-items: center;
    margin-top: 80px;
}

.cajon-core-value-default-mobile {
    display: flex;
    flex-direction: column;
    width: 225px;
    align-items: center;
    margin-top: 80px;
}