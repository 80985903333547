
.district-admin-data-dropdowns {
    display: flex;
}

.distrit-admin-data-chart {
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    margin: 10px;
    padding: 20px;
    min-width: 400px;
    max-width: 1100px;
}