.export-popup-title {
    color: #240145;
    font-weight: 800;
}

.export-popup-content {
    margin-left: 10px;
    margin-bottom: 10px;
    width: 550px;
}

.export-popup-content-mobile {
    margin-left: 10px;
    width: 320px;
}

.export-popup-fields {
    margin-left: 30px;
    margin-top: 10px;
}

.export-popup-italic-text {
    font-style: italic;
    font-size: small;
    margin-top: 15px;
    margin-bottom: 10px;
}

.export-date-selectors {
    margin-top: 20px;
    margin-bottom: 10px;
}

.export-date-picker {
    margin-right: 20px;
    margin-bottom: 10px;
}

.export-loading{
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}

#cancel-export-button {
    background-color: gray;
}