.edit-user-popup-title {
    color: #240145;
    font-weight: 800;
}

.edit-user-popup-content {
    margin-left: 10px;
    width: 550px;
}

.edit-user-popup-content-mobile {
    margin-left: 10px;
    width: 320px;
}

.edit-clever-user-warning-text {
    margin-bottom: 20px;
    font-style: italic;
}

.edit-user-bold-text {
    font-weight: bold;
    color: #240145;
}

.reactivate-user-text{
    margin-bottom: 20px;
}

.edit-user-field {
    margin: 10px;
    max-width: 400px;
}

.edit-user-loading {
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}

.create-user-instructions{
    margin-bottom: 10px;
}

.create-user-send-email-text  {
    margin-top: 10px;
}

.optional-fields-header {
    font-size: 18px;
    font-weight: 800;
    color: #240145;
}

.additional-schools-header {
    font-size: medium;
    font-weight: 800;
}

.additional-schools-subheader {
    font-style: italic;
    margin-left: auto;
    margin-right: 2px;
    float: right;
}

.additional-schools-instructions { 
    margin-bottom: 20px;
}

.additional-schools-help-icon {
    color: #240145;
    margin-left: 4px;
    vertical-align: middle;
    padding-bottom: 3px;
}

.optional-fields-accordian {
    margin-left: 10px;
    margin-top: 20px;
}


.edit-user-clever-fields-border {
    height: 100%;
    width: 90%;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    padding: 10px;
    margin: 10px;
}

.edit-user-clever-fields {
    margin-left: 10px;
}

.edit-user-clever-header {
    font-style: normal;
    color: #240145;
    font-weight: 800;
}

.clever-user-type-text {
    margin-bottom: 10px;
    margin-top: 20px;
}

#cancel-edit-user-button {
    background-color: gray;
}

#edit-user-email-field  {
    width: 250px;
}

#edit-user-type-field  {
    width: 90px;
}

#edit-user-role-field  {
    width: 250px;
}