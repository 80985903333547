.feed-box {
    margin: 20px;
}

.feed-tabs {
    border-color: #a2a1b7;
    border-bottom: 1px solid;
}

.feed-list-pagination {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 30px;
}

.my-feed-toggle-button {
    padding-top: 4px;
    padding-bottom: 4px;
    color: #240145;
}

.my-feed-toggle-button-group {
    margin-bottom: 10px;
}