.rewards-grid {
    margin: 20px;
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 30px;
}

.rewards-grid-mobile {
    margin-top: 20px;
    margin-left: 20px;
}

.rewards-tabs {
    border-color: #a2a1b7;
    border-bottom: 1px solid;
}

.rewards-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 40px;
    margin-top: 10px;
}

.purchased-rewards-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 40px;
}

.reward-loading {
    justify-content: center;
}

.no-rewards {
    justify-self: center;
}

.available-rewards-tab {
    padding-top: 0px;
}

.rewards-points-text {
    margin-top: 10px;
}