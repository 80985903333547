.pie-chart-box {
    height: 375px;
}

.pie-chart-box-print {
    height: 500px;
    width: 400px;
}

.pie-chart-label {
    fill: white;
    font-weight: 800;
    font-size: 20px;
}

#total-kreds-pie-chart-label-count {
    font-weight: 800;
    font-size: 28px;
    fill: #240145;
    text-align: center;
    transform: translateY(-10px);
}

#total-kreds-pie-chart-label-text {
    font-weight: 800;
    font-size: 12px;
    fill: #240145;
    transform: translateY(+15px);
}
