.notifications-border {
    border: 1px solid #A2A1B7;
    min-height: 100px;
    border-radius: 12px;
}

.notifications {
    display: flex;
    flex-direction: column;
}

.notifications-view-feed-button {
    color: gray;
    margin-top: 18px;
    align-self: end;
}

.no-notifications-text {
    text-align: left;
    font-style: italic;
}

.notifications-list-item {
    flex-direction: row;
}

.notification-list-item-text {
    margin-top: 4px;
    margin-bottom: 4px;
}

.notifications-time-text {
    text-align: left;
    font-style: italic;
    margin-top: 4px;
    margin-bottom: 4px;
}

.notification-giver-text {
    font-weight: bold;
    color: #240145;
}

.notification-token-text {
    font-weight: bold;
    color: #FCBE57;
}

.notification-star-icon {
    color: #FCBE57;
    align-self: flex-start;
    margin-right: 10px;
    margin-top: 4px;
}

.notification-kid-kred-coin-icon {
    height: 25px;
    align-self: flex-start;
    margin-top: 6px;
    margin-right: 10px;
}