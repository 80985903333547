.teacher-support-page {
    display: flex-block;
    width: 100%;
}

.teacher-support-grid {
    flex-grow: 1;
    padding: 30px;
    margin-left: 0px;
    justify-content: left;
}

.teacher-support-grid-mobile {
    margin-left: 4px;
    padding: 20px;
}

.teacher-support-grid-item-border {
    flex-grow: 1;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    padding: 20px;
    margin: 15px;
    min-width: 300px;
}

.teacher-main-support-box {
    min-width: 250px;
    max-width: 250px;
    height: 380px;
}

.teacher-main-support-box .support-questions {
    color: #240145;
    font-weight: 600;
}

.teacher-main-support-box .support-instructions {
    margin-top: 20px;
}

.teacher-main-support-box .logo {
    height: 100px;
    width: 100px;
    margin-top: 20px;
}

.support-sub-grid  {
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.support-card-border {
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    margin: 10px;
    height: 200px;
    width: 200px;
}

.support-card-action-area {
    height: 100%;
}

.support-card-content {
    padding: 20px;
    height: 100%;
}

.teacher-support-icon {
    margin-top: 8px;
    color: #fcbe57;
}

.teacher-support-sub-text {
    font-size: 14px;
}