.teacher-account-page {
    display: flex-block;
    width: 100%;
}

.teacher-account-grid {
    flex-grow: 1;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    overflow: auto;
}

.teacher-account-grid-item-box {
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    min-width: 270px;
    padding: 20px;
}

.teacher-account-profile-photo-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
}

.teacher-account-profile-photo-grid-item .teacher-account-grid-item-box {
    max-width: 350px;
}

.teacher-account-grid-item-header {
    text-align: left;
    color: #240145;
    margin-bottom: 10px;
    margin-top: 0px;
}

.teacher-account-avatar {
    width: 175px;
    height: 175px;
    display: inline-flex;
    font-size: 42px;
    margin-bottom: 8px;
}

.teacher-account-edit-profile-photo-text {
    font-style: italic;
    text-align: left;
    margin-left: 10px;
    margin-top: 8px;
    color: gray;
}

.change-profile-photo-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.change-profile-photo-action-button {
    margin: 4px;
    font-size: 12px;
}

.teacher-account-kreds-grid-item {
    display: inline-flex;
    flex-direction: column;
}

.teacher-account-kreds-grid-item .teacher-account-grid-item-box {
    max-width: 600px;
}

.teacher-account-kreds {
    display: inline-flex;
    flex-direction: row;
    margin-left: 8px;
    flex-wrap: wrap;
}

.teacher-account-kreds-box {
    height: 60px;
    width: 230px;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    margin-right: 10px;
    margin-top: 4px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.teacher-account-kreds-box .kreds-count-data {
    margin-left: 20px;
    text-align: left;
}

.teacher-account-kreds-box .kreds-count-data .kreds-count {
    color: #240145;
    font-weight: 600;
}

.teacher-account-kreds-box .kreds-text {
    color: gray;
}

.teacher-account-kreds-box .home-logo {
    align-self: flex-start;
    margin-top: 10px;
    margin-left: 10px;
}

.teacher-account-basic-data-grid-item .teacher-account-grid-item-box {
    max-width: 600px;
}

.teacher-account-edit-basic-data {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 8px;
}

.teacher-account-edit-basic-data-field-header {
    margin-top: 0px;
    margin-bottom: 0px;
}

.teacher-account-edit-basic-data-field {
    display: flex;
    flex-direction: row;
    min-height: 45px;
    align-items: center;
}

.teacher-account-edit-basic-data-field .field-display {
    display: inline-flex;
    margin-left: 10px;
    padding: 4px;
    margin-top: 6px;
    width: 90%;
    width: 410px;
}

.teacher-account-edit-basic-data-field .edit-field {
    display: inline-flex;
    margin-left: 10px;
    padding: 4px;
    margin-top: 6px;
    width: 90%;
    width: 410px;
}

.teacher-account-edit-basic-data {
    display: flex;
    flex-direction: column;
}

.teacher-account-edit-password {
    display: flex;
    margin-top: 20px;
}

.teacher-account-save-cancel-button .teacher-account-edit-button {
    height: 35px;
    width: 80px;
    margin-left: 10px;
    margin-bottom: 4px;
}

.teacher-account-save-cancel-button .teacher-account-save-cancel-buttom {
    display: inline-flex;
}

.teacher-account-personal-info {
    margin-left: 10px;
}

.clever-warning {
    margin-bottom: 10px;
    text-align: left;
}

.clever-warning-text {
    font-style: italic;
}

.user-account-star-icon {
    color: #fcbe57;
    margin-left: 20px;
}

.teacher-account-points-text {
    margin-bottom: 4px;
}

.teacher-account-sub {
    margin-top: 4px;
    margin-bottom: 8px;
}

.teacher-account-sub-text {
    font-style: italic;
    font-size: smaller;
}

.display-name-text {
    margin-left: 10px;
    max-width: 500px;
}

.account-error-icon {
    color:darkred;
    margin-left: 10px;
}

#cancel-edit-account-button {
    background-color: gray;
}

#remove-profile-photo-button {
    background-color: gray;
}

