
.home-logo {
    height: 40px;
}

.home-page-body {
    margin-top: 50px;
    margin-bottom: 100px;
    margin-right: 40px;
    margin-left: 30px;
}
.home-full-logo-box {
    flex-grow: 1;
    height: 40px;
}

.home-full-logo {
   height: 100%;
}


.welcome-text {
    font-size: 64px;
    color:#240145;
}

.medium-text {
    font-size: 24px;
    color:#240145;
    margin-top: 20px;
    margin-bottom: 20px;
}

.smaller-text {
    font-size: 18px;
    color:gray;
    margin-top: 20px;
    margin-bottom: 20px;
}

.bold-text {
    color: #240145;
    font-weight: 900;
}

.bold-text-black {
    font-weight: 900;
    color: black;
}

.home-sub-text {
    margin-top: 20px;
}

.home-page-privacy-policy-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.footer {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.privacy-policy-button {
    margin-right: 10px;
    color: gray;
}

.copy-right-footer {
   margin-left: 10px;
}

.home-page-big-login-button{
    width: 300px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.home-page-images {
    flex-grow: 1;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.home-page-laptop-image {
    height: 100%;
    align-self: center;
}