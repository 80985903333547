.rewards-card {
    margin: 10px;
    width: 255px;
    height: 290px;
    border-radius: 30px;
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
}

.rewards-card-title {
    font-weight: 600;
}

.rewards-card-content {
    padding-bottom: 5px;
    height: 114px;
    text-align: center;
}

.rewards-card-additional-fields {
    min-height: 20px;
}

.rewards-card-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4px;
    position: relative;
}

.rewards-card-actions .rewards-card-button {
    line-height: 1.5;
    border-radius: 20px;
    position: absolute;
}

.rewards-card-button-enabled {
    background-color: #f59400;
    color: white;

}

.rewards-card-actions .reward-info {
    display: flex;
    margin-left: auto;
    margin-right: 8px;
    align-self: flex-end;

}