.cajon-rewards {
    padding: 30px
}

.cajon-rewards-mobile {
    padding: 15px;
}


.cajon-rewards-border {
    width: 600px;
    border: 1px solid #A2A1B7;
    border-radius: 12px;
    margin-left: 15px;
    padding: 20px;
}

.cajon-rewards-border-mobile {
    width: 250px;
    border: 1px solid #A2A1B7;
    border-radius: 12px;
    margin-left: 15px;
    padding: 20px;
}

.cajon-rewards-text {
    margin-top: 20px;
}

.cajon-rewards-bold-text {
    font-weight: 800;
}

.cajon-reward-img {
    width: 300px;
}

.cajon-reward-img-mobile{
    width: 200px;
}

.cajon-rewards-button {
    margin-top: 20px;
}