.recognition-page {
    display: flex-block;
    width: 100%;
}

.hilight-count-section {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.hilight-count-section-text {
    display: inline;
    padding-left: 30px; 
    padding-right: 30px;
}

.hilight-count-section-info-icon {
    margin-left: 4px;
    color: gray;
    vertical-align: middle;
    padding-bottom: 3px;
}

.number-of-hilights {
    font-size: large;
    font-weight: bold;
    color: #fcbe57
}

.recognition-grid {
    flex-grow: 1;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    overflow: auto;
}

.recognition-grid-item {
    margin: 30px;
    text-align: left;
}

.recognition-grid-item-mobile {
    margin: 20px;
    text-align: left;
}

.recognition-send-header {
    display: flex;
}

.recognition-grid-item-header {
    text-align: left;
    color: #240145;
    margin-top: 0px;
}

.hilight-option-buttons {
    margin-right: 0px;
    margin-left: auto;
    display: flex;
}

.district-hilight-button {
    margin-left: 4px;
}

.student-hilight-button {
    margin-right: 4px;
    margin-left: auto;
}


.send-recognition-form {
    min-width: 300px;
}

.recognition-form-section {
    display: flex;
}

.send-recognition-border {
    height: auto;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
}

.notifications-grid-item {
    min-width: 250px;
}

.recognition-form-loading {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.cajon-mvp-img {
    width: 240px;
    margin-bottom: 10px;
}