.App {
    text-align: center;
    font-family: Catamaran, sans-serif;
    background-color: white;
}

.page-heading {
    color: #240145;
    font-weight: 600;
    font-size: 26px;
    margin-left: 30px;
    margin-top: 25px;
    text-align: left;
}

.loading-logo {
    height: 200px;
    width: 200px;
    margin-top: 10px;
    margin-left: 10px;
}

.loading-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-text {
    text-align: center;
    color: #240145;
    font-size: 26px;
}

.loading-animation {
    align-self: center;
}
