.bonus-point {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.bonus-point-icon {
    color: #fcbe57;
    margin-right: 10px;
}

.bonus-point-info-icon {
    margin-left: 4px;
    color: gray;
    vertical-align: middle;
    padding-bottom: 3px;
}


#close-recognition-popup-button {
    background-color: gray;
}
