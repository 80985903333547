
.certificate-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certificate-content {
    width: 700px;
    height: 540px;
}

.certificate-outer-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certificate-sub-box {
    display: flex;
    align-items: center;
}

.certificate-interior {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 80px;
}

/* Certificate styling */
.certificate-name {
    margin-top: 88px;
    height: 45px;
    display: flex;
    align-items: center;
}

.certificate-bold-text {
    font-weight: 800;
    color: #153056;
}

.certificate-sender {
    margin-top: 24px;
    height: 40px;
    display: flex;
    align-items: center;
}
.certificate-core-value-image {
    margin-top: 40px;
    width: 150px;
    height: 68px;
}

.certificate-date {
    margin-top: 0px;
}

.certificate-hilight-text {
    font-style: italic;
    width: 340px;
    height: 190px;
    margin-top: 0px;
    text-align: center;
    color:#153056;
    
}

.certificate-image {
    display: block;
    width: 100%;
    height: 100%;
}

.certificate-inner-box  {
    width: 700px;
    height: 540px;
    position: relative;
    border: 1px solid #a2a1b7;
}

.certificate-interior-box {
    position:absolute; 
    top:0; 
    left:0; 
    width:100%; 
    height:100%;
}