.admin-data-box {
    margin: 20px;
}

.admin-data-tabs {
    border-color: #a2a1b7;
    border-bottom: 1px solid;
}

.admin-dashboard-grid-item-header {
    text-align: left;
    color: #240145;
    margin-bottom: 10px;
    margin-top: 0px;
}

.admin-dashboard-grid-item-border {
    height: 100%;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    padding: 30px;
    min-width: 720px;
}

.admin-dashboard-subtitle {
    font-style: italic;
    text-align: left;
    margin-bottom: 10px;
}

.admin-data-dashboard-dropdowns {
    display: flex;
    flex-direction: row;
}

.data-dashboard-school-dropdown {
    margin-bottom: 20px;
    margin-right: 20px;
    width: 250px;
}

.data-dashboard-year-dropdown{
    margin-bottom: 10px;
}

.personal-data-tab-panel {
    padding-top: 4px;
}

.school-year-dropdown {
    width: 230px;
}