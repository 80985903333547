.overview-metrics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.overview-metrics-item-border {
    border: 1px solid #a2a1b7;
    border-radius: 12px;
    margin: 10px;
    padding: 10px;
    height: 110px;
    width: 200px;
}

.overview-metrics-item-text {
    color: #240145;
    font-weight: 700;
    font-size: medium;
}

.overview-metrics-number {
    font-weight: bold;
    font-size: x-large;
    color: #fcbe57;
    margin-left: 4px;
    margin-right: 4px;
}
