.send-recognition-form {
    min-width: 300px;
}

.recognition-form-section {
    display: flex;
}

.send-recognition-border {
    height: auto;
    border: 1px solid #a2a1b7;
    border-radius: 12px;
}

.send-recognition-form-item {
    margin-bottom: 20px;
    width: 100%;
}

.select-teacher-name {
    min-width: 220px;
    width: 80%;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    text-align: left;
}

.give-button {
    width: 150px;
    align-self: end;
}
.select-token-type {
    /* border: 1px solid #A2A1B7; */
    /* border-radius: 12px; */
    display: flex;
    text-align: left;
    min-width: 210px;
}

.recognition-out-of-tokens-alert {
    display: inline-flex;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 4px;
}

.recognition-out-of-tokens-alert-text {
    font-style: italic;
    text-align: left;
    margin-top: 1px;
    margin-left: 4px;
}

.student-error-message {
    display: flex;
    margin-bottom: 10px;
}

.student-erorr-icon {
    color:darkred;
    margin-right: 10px;
}

.recognition-out-of-tokens-alert-icon {
    color: #fcbe57;
}

.kid-kred-icon-faded {
    filter: opacity(0.4);
}

.select-token-list {
    margin-left: 20px;
}

.cajon-token-list {
    display: flex;
    flex-direction: row;
}

.cajon-token-list-mobile {
    display: flex;
    flex-direction: row;
    margin-left: 2px;
}

.select-token-checkbox {
    padding: 0px;
    margin-top: 10px;
}

.select-token-icon {
    height: 25px;
    color: #fcbe57;
}

.select-token-label .select-token-label-text {
    margin-top: 10px;
    margin-left: 10px;
}

.select-token-label-disabled {
    color: gray;
}

.notifications-grid-item {
    min-width: 300px;
}

.recognition-form-label {
    margin-bottom: 5px;
}

.recognition-text-field {
    padding-top: 20px;
}

.recognition-text {
    width: 100%;
}

.teacher-select {
    margin-top: 10px;
}

.recognition-form-submit {
    display: flex;
    justify-content: right;
}

.district-recognition-form {
    padding-top: 10px;
}

.cajon-mvp-img-mobile {
    width: 150px;
    margin-bottom: 10px;
}

.cajon-form-heading {
    justify-content: center;
}

.recognition-form-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    padding-top: 90px
}

.recognition-loading-text {
    color:#240145;
    margin-top: 10px;
}