.feed-page {
    display: flex-block;
    width: 100%;
}

.feed-page-header {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
}

.bulletin-dropdown {
    margin-top: 20px;
    margin-right: 40px;
    min-width: 120px;
    margin-left: auto;
}
.feed-grid-box-mobile {
    flex-grow: 1;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: auto;
}

.feed-grid-box {
    flex-grow: 1;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    overflow: auto;
}

.feed-grid .feed-grid-item-mobile {
    padding-left: 20px;
    padding-top: 10px;
}

.feed-grid .feed-grid-item {
    padding-top: 0px;
}

.feed {
    min-height: 200px;
}

.paginated-feed-list-box .no-data {
    margin-top: 50px;
}
