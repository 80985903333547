.user-side-bar-avatar {
    width: 100px;
    height: 100px;
    display: inline-flex;
    font-size: 42px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.user-display-name {
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
}

.user-side-bar-user-role {
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

.user-side-bar-kreds {
    display: inline-flex;
    height: 85px;
    align-items: center;
}

.user-side-bar-kreds .to-give {
    margin-right: 15px;
}

.user-side-bar-kreds .earned {
    margin-left: 15px;
}

.user-kreds-tool-tip-text {
    font-size: 14px;
}

.kred-count {
    color: #fcbe57;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 10px;
}

.kred-type {
    font-weight: 500;
    size: 20px;
    margin-top: 0px;
}

.kreds-divider {
    background-color: white;
}

.side-bar-hilights-help {
    font-size: 16px;
    margin-left: 4px
}

.points-count {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.points-count-text {
    font-weight: bold;
    color: #FCBE57;
}