.popup-bold {
    font-weight: 700;
}

.popup-purple-text {
    color: #240145;
}

.popup-orange-text {
    font-weight: 700;
    color: #f59400;
}

.popup-help-icon {
    color: #240145;
}
