.hilight-text {
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.remove-hilight-info-icon {
    margin-left: 5px;
    margin-right: auto;
    color: gray;
}

.remove-hilight-progress {
    display: flex;
    justify-content: center;
    margin-top: 10px;

}

.remove-hilight-dialog-content {
    width: 600px;
    min-height: 130px;
}

.emove-hilight-dialog-content-mobile {
    width: 220px;
    height: 170px;
}
